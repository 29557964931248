import React from 'react';
import { LookingForSomethingElse } from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import FrameCards from 'src/components/FrameCards';
import useFeatureFlags from 'src/hooks/useFeatureFlags';
import { FeatureFlagsEnum } from 'src/utils/enums';
import { Header } from './styles';
import { screenName, screenPath } from 'src/utils/analytics';

const Help = () => {
  const { translate } = useDependencyManagerContext();

  const {
    featureFlagValue: originalFeedbackExperience,
    isLoading: isOriginalFeedbackExperienceLoading
  } = useFeatureFlags(FeatureFlagsEnum.originalFeedbackExperience);

  const showOriginalFeedbackExperience =
    originalFeedbackExperience && !isOriginalFeedbackExperienceLoading;

  return (
    <>
      <Header data-testid="header">
        <h4>{`${translate('menu.support', 'Help & support')}`}</h4>
        <p>
          {`${translate(
            'support.getSupport',
            'Get support for your HP account and devices'
          )}`}
        </p>
      </Header>
      <FrameCards />
      {showOriginalFeedbackExperience && (
        <LookingForSomethingElse
          analytics={{
            screenName: screenName,
            screenPath: screenPath
          }}
          translate={translate}
        />
      )}
    </>
  );
};
export default Help;
